import React from "react"

const Certificate = (props: React.SVGProps<any>) => (
  <svg
    color="currentColor"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 5C2 3.89543 2.89543 3 4 3H16C17.1046 3 18 3.89543 18 5V8.14584C17.7001 7.87745 17.3641 7.64868 17 7.46776V5C17 4.44772 16.5523 4 16 4H4C3.44772 4 3 4.44772 3 5V13C3 13.5523 3.44772 14 4 14H11.2578C11.3336 14.1133 11.4145 14.223 11.5 14.3287V15H4C2.89543 15 2 14.1046 2 13V5ZM18.5 11.5C18.5 12.4538 18.1185 13.3184 17.4998 13.9497L17.5 18C17.5 18.412 17.0296 18.6472 16.7 18.4L15.3 17.35C15.1222 17.2167 14.8778 17.2167 14.7 17.35L13.3 18.4C12.9704 18.6472 12.5 18.412 12.5 18V13.9495C11.8814 13.3182 11.5 12.4537 11.5 11.5C11.5 9.567 13.067 8 15 8C16.933 8 18.5 9.567 18.5 11.5ZM15 15C14.4629 15 13.9549 14.8794 13.5 14.6632V16.75L14.7428 16.0043C14.9011 15.9093 15.0989 15.9093 15.2572 16.0043L16.5 16.75V14.6632C16.0454 14.8792 15.5368 15 15 15ZM15 14C16.3807 14 17.5 12.8807 17.5 11.5C17.5 10.1193 16.3807 9 15 9C13.6193 9 12.5 10.1193 12.5 11.5C12.5 12.8807 13.6193 14 15 14ZM5 6.5C5 6.22386 5.22386 6 5.5 6H14.5C14.7761 6 15 6.22386 15 6.5C15 6.77614 14.7761 7 14.5 7H5.5C5.22386 7 5 6.77614 5 6.5ZM5.5 11C5.22386 11 5 11.2239 5 11.5C5 11.7761 5.22386 12 5.5 12H9.5C9.77614 12 10 11.7761 10 11.5C10 11.2239 9.77614 11 9.5 11H5.5Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
)

export default Certificate
