import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  SubMenu,
} from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Link from "next/link"

import { Calendar } from "iconoir-react"

import NoSSR from "components/shared/NoSSR"
import PreserveQueryParams from "components/shared/PreserveQueryParams"

import Confidential from "./Confidential"

const routes = {
  talkToMentor: "/talk-to-a-mentor",
  itoTeacher: "https://api.whatsapp.com/send/?phone=919986702707&text=HI",
  itoSchool: "mailto:partnerships@teachersolympiad.com",
}

// This is useful during ITO
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CTAButtonWithMenu = (props: ButtonProps) => (
  <PreserveQueryParams
    href={routes.talkToMentor}
    render={href => (
      <Menu menuButton={<Button {...props}>Talk To A Mentor</Button>}>
        <MenuItem className="!w-max cursor-pointer" href={href}>
          Course Related Queries
        </MenuItem>
        <MenuItem className="!w-max cursor-pointer" href={href}>
          Career Related Queries
        </MenuItem>
        <SubMenu
          label={
            <Button className="!p-0 !bg-transparent !text-onSurface-900 !text-base !font-normal">
              ITO Related Queries
            </Button>
          }
        >
          <MenuItem
            className="cursor-pointer"
            href={routes.itoTeacher}
            target="_blank"
          >
            ITO: Teachers
          </MenuItem>
          <MenuItem
            className="cursor-pointer"
            href={routes.itoSchool}
            target="_blank"
          >
            ITO: Schools
          </MenuItem>
        </SubMenu>
      </Menu>
    )}
    isRelative
  />
)

const CTAButton = ({
  tagClassName,
  hideTag = false,
  label = "Talk To A Mentor",
  containerClassName,
  ...props
}: ButtonProps & {
  hideTag?: boolean
  tagClassName?: string
  containerClassName?: string
}) => (
  <NoSSR>
    <div className={clsx(containerClassName, "flex flex-col")}>
      <PreserveQueryParams
        href="/talk-to-a-mentor"
        render={href => (
          <Link href={href}>
            <Button label={label} startAdornment={<Calendar />} {...props} />
          </Link>
        )}
        isRelative
      />
      {!hideTag && (
        <Confidential
          className={clsx("mt-1", tagClassName, {
            "mx-auto": props?.fullWidth,
          })}
        />
      )}
    </div>
  </NoSSR>
)

export default CTAButton
